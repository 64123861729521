.MaterialDisplay {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
}

.spinner-icon {
    width: 12vmin;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}