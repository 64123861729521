.MaterialSelect {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    border-color: gray;
    border-top-left-radius: 1.1rem;
    border-top-right-radius: 1.1rem;
    border-bottom: 0;
    border-style: solid;
    border-width: 0.15rem;
    margin-top: 1.5rem;

    .MaterialList {
        height: 110vh;
        overflow: scroll;
        background-color: rgba(0, 0, 0, 0.300);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        padding: 0.3rem;
        padding-top: 1rem;
        padding-bottom: 2rem;
        
    }

    .input-cont {
        background-color: white;
        width: 100%;
        height: 3rem !important;
        border-radius: 0 !important; 
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important;
        border-color: rgb(255, 255, 255) !important;
        
    }

    Input {
        font-family: "FOT-Rodin-B";

        &:hover {
            background-color: white;
            border-color: rgb(255, 255, 255);
        }

        &:focus {
            background-color: white;
            border-color: rgb(255, 255, 255);
        }

    }

   

}

.MaterialSelect::-webkit-scrollbar {
    display: none;
}

.MaterialList::-webkit-scrollbar {
    display: none;
}

.spinner-icon {
    width: 12vmin;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}