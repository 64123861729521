.DishSelect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-color: gray;
    border-radius: 0.5rem;
    border-width: 0.15rem;
    border-style: solid;
    background-color: rgba(0, 0, 0, 0.300);
    padding: 1rem;
    

    img {
        height: 90%;
        margin: -2rem;
    }

    .Dish-Text {
        color: white;
        text-align: center;
        margin-top: 1rem;
    }
}
