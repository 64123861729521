.StatDisplay {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    .CritChance {
        color: gold;
        margin-top: 0.2rem;
        margin-bottom: 0;
    }

    .MonsterExtract{
        color: rgb(215, 105, 255);
        margin-top: 0.2rem;
        margin-bottom: 0;
        
    }

    .StatDisplayContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 2rem;
        //margin-bottom: 2rem;


        .HPDisplay {
            background-color: gray;
            border-radius: 0.2rem;
            border-color: gray;
            border-style: solid;
            border-width: 0.15rem;
            background-color: rgba(0, 0, 0, 0.500);
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 0.5rem;
            height: 100%;
            
            

            img {
                width: 1.5rem;
                margin: 0.5rem;
            }

            p {
                color: white;
                margin-right: 0.5rem;
            }
        }

        .EffectDisplay {
            background-color: gray;
            border-radius: 0.2rem;
            border-color: gray;
            border-style: solid;
            border-width: 0.15rem;
            background-color: rgba(0, 0, 0, 0.504);
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 0.5rem;
            height: 100%;

            img {
                width: 1.5rem;
                margin: 0.5rem;
                margin-right: 0rem;
            }

            &.StaminaRegen {
                img {
                    width: 1.5rem;
                    margin: 0.5rem;
                }
                
            }

            &.StaminaUp {
                img {
                    width: 1.5rem;
                    margin: 0.5rem;
                }
                
            }

            &.GloomHeal {
                img {
                    width: 2rem;
                    margin-top: 0.3rem;
                    
                }
            }

            &.Default {

                img {
                    margin-left: 0.5rem;
                    margin-top: 0.4rem;
                    margin-bottom: 0.4rem;
                    margin-right: 0;
                }

            }

            p {
                color: white;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
            }

            .effect-duration {
                margin-left: 0;
            }


        }

        .price {
            background-color: gray;
            border-radius: 0.2rem;
            border-color: gray;
            border-style: solid;
            border-width: 0.15rem;
            background-color: rgba(0, 0, 0, 0.504);
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 0rem;
            color: white;
            height: 100%;

            img {
                width: 1rem;
                margin: 0.5rem;
            }

            p {
                color: white;
                margin-right: 0.5rem;
                
            }
        }
    }

}